export type ClosingEvent = CustomEvent;
export type ClosedEvent = CustomEvent;
export type OpeningEvent = CustomEvent;
export type OpenedEvent = CustomEvent;
type Position = "right" | "left";

export const DRAWER_CLOSED = "closed";
export const DRAWER_CLOSING = "closing";
export const DRAWER_OPENED = "opened";
export const DRAWER_OPENING = "opening";

const breakpoint = "768px";
const duration = "200ms";

const template = document.createElement("template");

template.innerHTML = /* HTML */ `
  <style>
    :host {
      inset: 0;
      pointer-events: none;
      position: fixed;
      z-index: 2000000;
    }

    :host([opened]) {
      pointer-events: auto;
      visibility: visible;
    }

    :host([position="right"]) > .drawer-container {
      left: auto;
      right: 0;
    }

    .drawer-scrim {
      background: rgba(0, 0, 0, 0.2);
      inset: 0;
      opacity: 0;
      position: absolute;
      transition: opacity ${duration} ease-out;
    }

    :host([opened]) .drawer-scrim {
      opacity: 1;
    }

    .drawer-container {
      background-color: var(--ws-drawer-background-color, #fff);
      display: flex;
      flex-direction: column;
      inset: 0 auto 0 0;
      position: absolute;
      transform: translateX(0);
      width: min(100%, 440px);
      z-index: 1;
    }

    @media (min-width: ${breakpoint}) {
      .drawer-container {
        transform: translateX(-100%);
        opacity: 1;
      }
    }

    :host([closed]) .drawer-container {
      visibility: hidden;
    }

    @media (min-width: ${breakpoint}) {
      :host([position="right"]) .drawer-container {
        transform: translateX(100%);
      }
    }

    :host([opened]) .drawer-container {
      transform: translateX(0%);
      opacity: 1;
      overflow-y: scroll;
      visibility: visible;
    }

    .drawer-header {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      padding: var(--ws-drawer-header-padding, 0);
      position: sticky;
      top: 0;
      background-color: var(--ws-drawer-background-color, #fff);
      z-index: 1;
    }

    :host([header-shadow]) .drawer-header {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
    }

    .drawer-close-button {
      align-items: center;
      appearance: none;
      background-color: var(--ws-drawer-background-color, #fff);
      border-radius: 0.25rem;
      border: none;
      color: var(--ws-drawer-color, #000);
      cursor: pointer;
      display: flex;
      flex-shrink: 0;
      font: 500 1rem /1.2 var(--ws-drawer-close-text-font-family);
      gap: 0.5rem;
      margin: var(--ws-drawer-close-button-margin, 0);
      padding: var(--ws-drawer-close-button-padding, 0);
      transition: background-color 0.3s;

      &:hover,
      &:focus-visible {
        --ws-drawer-background-color: var(
          --ws-drawer-close-button-background-color--hover,
          #fff
        );
      }

      &:active {
        --ws-drawer-background-color: var(
          --ws-drawer-close-button-background-color--active,
          #fff
        );
      }
    }

    .drawer-close-icon::before {
      content: var(--ws-drawer-close-icon-content);
      display: flex;
      font: normal 1.5rem/1 var(--ws-drawer-close-icon-font-family);
    }

    :host([position="right"]) .drawer-close-button {
      flex-direction: row-reverse;
      margin-left: auto;
    }

    .drawer-footer {
      margin-top: auto;
    }

    @media (prefers-reduced-motion: no-preference) {
      @keyframes animateOpen {
        to {
          transform: translateX(0%);
        }
      }

      @keyframes animateClose {
        from {
          transform: translateX(0%);
        }
      }

      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      @keyframes fadeOut {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }

      :host([closing]) .drawer-container {
        animation-name: fadeOut;
        animation-duration: ${duration};
        animation-fill-mode: forwards;
      }

      :host([opening]) .drawer-container {
        animation-name: fadeIn;
        animation-duration: ${duration};
        animation-fill-mode: forwards;
        animation-delay: 0;
      }

      :host([opening]) .drawer-scrim {
        animation-name: fadeIn;
        animation-duration: ${duration};
        animation-fill-mode: forwards;
        animation-delay: 0;
      }

      @media (min-width: ${breakpoint}) {
        :host([closing]) .drawer-container {
          animation-name: animateClose;
        }

        :host([opening]) .drawer-container {
          animation-name: animateOpen;
          animation-delay: 0;
        }

        :host([opening]) .drawer-container {
          animation-delay: 0;
        }
      }
    }
  </style>

  <div class="drawer-scrim" aria-hidden="true"></div>
  <div class="drawer-container">
    <header class="drawer-header">
      <button class="drawer-close-button" aria-label="schließen">
        <i class="drawer-close-icon"></i>
        <span>Schließen</span>
      </button>
    </header>
    <slot></slot>
    <footer class="drawer-footer">
      <slot name="footer"></slot>
    </footer>
  </div>
`;

export class Drawer extends HTMLElement {
  static observedAttributes = [
    "opening",
    "opened",
    "closing",
    "closed",
    "position",
    "header-shadow",
  ];

  #openButton: HTMLElement | null;
  #closeButton: Element;
  #scrim: Element;
  #container: HTMLElement;
  #defaultSlot: HTMLSlotElement;
  #focusableElements: HTMLElement[];
  #firstFocusableElement: HTMLElement;
  #lastFocusableElement: HTMLElement;

  // gets the position attribute (possible answers "right" or "left")
  get position(): Position {
    return (this.getAttribute("position") as Position) || "left";
  }

  // sets the position attribute, only "right" or "left" are possible
  set position(value: Position) {
    this.setAttribute("position", value.toLowerCase());
  }

  // gets the opening attribute
  get opening() {
    return this.hasAttribute("opening");
  }

  // sets the opening attribute
  set opening(value: boolean) {
    if (value) {
      this.setAttribute("opening", "");
    } else {
      this.removeAttribute("opening");
    }
  }

  // gets the opened attribute
  get opened() {
    return this.hasAttribute("opened");
  }

  // setter for the opened attribute
  set opened(value: boolean) {
    if (value) {
      this.setAttribute("opened", "");
    } else {
      this.removeAttribute("opened");
    }
  }

  // getter for the closing attribute
  get closing() {
    return this.hasAttribute("closing");
  }

  // setter for the closing attribute
  set closing(value: boolean) {
    if (value) {
      this.setAttribute("closing", "");
    } else {
      this.removeAttribute("closing");
    }
  }

  // getter for the closed attribute
  get closed() {
    return this.hasAttribute("closed");
  }

  // setter for the closed attribute
  set closed(value: boolean) {
    if (value) {
      this.setAttribute("closed", "");
    } else {
      this.removeAttribute("closed");
    }
  }

  // getter for the headerShadow attribute
  get headerShadow() {
    return this.hasAttribute("header-shadow");
  }

  // setter for the headerShadow attribute
  set headerShadow(value: boolean) {
    if (value) {
      this.setAttribute("header-shadow", "");
    } else {
      this.removeAttribute("header-shadow");
    }
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot!.appendChild(template.content.cloneNode(true));

    // initializes all needed elements
    this.#openButton = null;
    this.#closeButton = this.shadowRoot!.querySelector(
      "button.drawer-close-button",
    )!;
    this.#scrim = this.shadowRoot!.querySelector("div.drawer-scrim")!;
    this.#container = this.shadowRoot!.querySelector("div.drawer-container")!;
    this.#defaultSlot = this.shadowRoot!.querySelector("slot")!;

    // gets all focusable elements
    const buttons = Array.from(this.shadowRoot!.querySelectorAll("button"));
    let links: HTMLElement[] = [];
    for (const node of this.#defaultSlot.assignedNodes()) {
      if (!(node instanceof HTMLElement)) continue;
      links = links.concat(
        Array.from(
          node.querySelectorAll(
            "a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex], [contenteditable]",
          ),
        ),
      );
    }
    this.#focusableElements = [...buttons, ...links];
    this.#firstFocusableElement = this.#focusableElements[0]!;
    this.#lastFocusableElement =
      this.#focusableElements[this.#focusableElements.length - 1]!;

    this.#correctAttributesWhenStart();
  }

  // Sets the correct attributes when for the initial start of the menu. Only called from constructor
  #correctAttributesWhenStart() {
    if (this.opened) {
      this.#enableTabFocus();
      this.#autoFocus();
      this.closed = false;
    } else {
      this.closed = true;
      this.#disableTabFocus();
    }
  }

  // connects all needed eventlisteners
  connectedCallback() {
    /* openButton is on reload not available in the DOM therefore we need to move it to connected callback (lint error) */
    this.#openButton = document.querySelector(
      `[data-drawertarget="${this.id}"]`,
    ) as HTMLElement | null;

    //Enable open Button
    if (this.#openButton) {
      this.#openButton.addEventListener("click", this.toggle);
    }

    //Enable close Button
    this.#closeButton.addEventListener("click", this.toggle);

    //Enable scrim to close Menu
    this.#scrim.addEventListener("click", this.toggle);

    //Enable escape Button to close the Menu
    document.addEventListener("keydown", this.#handleButtonPress);
  }

  // close when escape button is pressed
  #handleButtonPress = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      void this.close();
    } else if (event.key === "Tab" || event.keyCode === 9) {
      this.#trapFocus(event);
    } else {
      return;
    }
  };

  // traps the focus within the menu, if keyboard is used
  #trapFocus(event: KeyboardEvent) {
    if (!(event.key === "Tab")) {
      return;
    }

    const activeElement =
      document.activeElement === this
        ? this.shadowRoot!.activeElement
        : document.activeElement;

    if (event.shiftKey) {
      /* shift + tab */
      if (activeElement === this.#firstFocusableElement) {
        this.#lastFocusableElement.focus();
        event.preventDefault();
      }
    } /* tab */ else {
      if (activeElement === this.#lastFocusableElement) {
        this.#firstFocusableElement.focus();
        event.preventDefault();
      }
    }
  }

  #autoFocus() {
    const autoFocusElement = this.querySelector("[autofocus]") as HTMLElement;
    const positionOfAutofocusElementWithinMenu =
      this.#focusableElements.indexOf(autoFocusElement);
    (
      this.#focusableElements[positionOfAutofocusElementWithinMenu]! ??
      this.#focusableElements[0]
    ).focus();
  }

  // opens the menu
  open = async () => {
    if (this.opened) {
      return;
    }
    this.closed = false;
    this.opening = true;

    const openingEvent: OpeningEvent = new CustomEvent(DRAWER_OPENING);
    this.dispatchEvent(openingEvent);

    await this.#animationsCompleted(this.#container);

    this.opened = true;
    this.opening = false;

    const openedEvent: OpenedEvent = new CustomEvent(DRAWER_OPENED);
    this.dispatchEvent(openedEvent);
    this.#scrim.setAttribute("aria-hidden", "false");
    this.#enableTabFocus();
    this.#autoFocus();
  };

  //closes the menu
  close = async () => {
    if (!this.opened) {
      return;
    }
    this.opened = false;
    this.closing = true;

    const closingEvent: ClosingEvent = new CustomEvent(DRAWER_CLOSING);
    this.dispatchEvent(closingEvent);

    await this.#animationsCompleted(this.#container);

    this.closed = true;
    this.closing = false;

    const closedEvent: ClosedEvent = new CustomEvent(DRAWER_CLOSED);
    this.dispatchEvent(closedEvent);

    this.#scrim.setAttribute("aria-hidden", "true");
    this.#disableTabFocus();
    if (this.#openButton) {
      this.#openButton.focus();
    }
  };

  // Waits for the animations to complete and returns the Promise
  #animationsCompleted(element: HTMLElement) {
    return Promise.allSettled(
      element.getAnimations().map((animation) => animation.finished),
    );
  }

  // toggles between open and closed
  toggle = () => {
    void (this.opened ? this.close() : this.open());
  };

  // enables the Tab Focus, so interacting with the menu per Tab is possible
  #enableTabFocus() {
    this.#focusableElements.forEach((element) => {
      // Explicitly set tabindex to 0 to enable focus for isHiddenForBots links in menu
      element.setAttribute("tabindex", "0");
    });
  }

  // disables the Tab Focus
  #disableTabFocus() {
    this.#focusableElements.forEach((element) => {
      element.setAttribute("tabindex", "-1");
    });
  }

  // disconnects all eventlisteners
  disconnectedCallback() {
    if (this.#openButton) {
      this.#openButton.removeEventListener("click", this.toggle);
    }
    this.#closeButton.removeEventListener("click", this.toggle);
    this.#scrim.removeEventListener("click", this.toggle);
    document.removeEventListener("keydown", this.#handleButtonPress);
  }
}

customElements.get("ws-drawer") ?? customElements.define("ws-drawer", Drawer);

declare global {
  interface HTMLElementTagNameMap {
    "ws-drawer": Drawer;
  }
}
