import type { Token } from "./token";

const template = document.createElement("template");

template.innerHTML = `
  <style>
    :host {
      display: block;
    }
    :host([hidden]) {
      display: none;
    }
  </style>
  <slot></slot>
`;

export const ariaLabelActive = "Von Merkliste entfernen";
export const ariaLabelInactive = "Zur Merkliste hinzufügen";
export const iconClassActive = "icon-bookmark-filled";
export const iconClassInactive = "icon-bookmark";

/**
 * Bookmark button
 * https://rtltech.atlassian.net/wiki/spaces/PAID/pages/212439140/05+Architektur+-+Merkliste
 */
export class BookmarkButton extends HTMLElement {
  static observedAttributes = ["active", "disabled"];

  #bookmarkButton: HTMLElement;
  #bookmarkIcon: HTMLElement;

  get csrfRef(): string {
    return this.getAttribute("csrf-ref") || "";
  }

  get contentId(): string {
    return this.getAttribute("content-id") || "";
  }

  get paidCategory(): string | null {
    return this.getAttribute("paid-category");
  }

  get disabled(): boolean {
    return this.hasAttribute("disabled");
  }

  get active(): boolean {
    return this.hasAttribute("active");
  }

  set active(value) {
    value ? this.setAttribute("active", "") : this.removeAttribute("active");
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot!.appendChild(template.content.cloneNode(true));
    this.#bookmarkButton = this.shadowRoot!.querySelector(
      "slot",
    )!.assignedElements()[0]! as HTMLElement;
    this.#bookmarkIcon = this.#bookmarkButton.querySelector("i")!;
  }

  connectedCallback() {
    this.#setButtonAriaLabelAndIconClass(this.active);
    this.#initialize();
    this.#registerEvents();
  }

  #initialize() {
    if (this.disabled) {
      return;
    }
    this.#load();
  }

  #registerEvents() {
    this.#bookmarkButton.addEventListener("click", () => {
      if (!this.disabled) {
        this.active ? this.#deleteBookmark() : this.#addBookmark();
      }
    });
  }

  async #load() {
    if (!this.active) {
      this.active = await this.#getBookmarked();
    }
  }

  async #getCSRFToken() {
    const tokenProvider: Token | null = document.querySelector(this.csrfRef);

    if (!tokenProvider) {
      throw new Error(
        "Attribute 'csrf-ref' must point to a valid CSRF token provider.",
      );
    }

    return await window.customElements
      .whenDefined(tokenProvider.tagName.toLowerCase())
      .then(async () => {
        if (!tokenProvider || !("token" in tokenProvider)) {
          throw new Error(
            "Attribute 'csrf-ref' must point to a valid CSRF token provider.",
          );
        }
        // Get token string
        return await tokenProvider.token;
      });
  }

  // Get bookmark status via REST-API (e.g. https://www.dev.stern.de/p-api/lists/bookmarks/items)
  async #getBookmarked() {
    const apiUrl = `/p-api/lists/bookmarks/items?contentId=${this.contentId}`;
    let result = null;
    try {
      result = await this.#fetchResult(apiUrl);
    } catch (error) {
      this.#handleError(error);
    }
    return result?.items?.length > 0;
  }

  // Add a bookmark via REST-API (e.g. https://www.dev.stern.de/p-api/lists/bookmarks/items)
  async #addBookmark() {
    this.active = true;
    const apiUrl = `/p-api/lists/bookmarks/items`;
    try {
      const csrfToken = await this.#getCSRFToken();
      if (!csrfToken) {
        throw new Error("Request failed: CSRF token is not defined.");
      }
      const response = await fetch(apiUrl, {
        method: "POST",
        body: JSON.stringify({
          contentId: this.contentId,
          paidCategories: this.paidCategory ? this.paidCategory.split(",") : [],
        }),
        headers: {
          "X-CSRF-Token": csrfToken,
        },
      });
      if (!response.ok) {
        throw new Error(
          `Request failed: ${response.status} - ${response.statusText}`,
        );
      }
      this.#triggerEvent("toggleBookmark", { action: "add" });
    } catch (error) {
      // Reset on error
      this.active = false;
      this.#handleError(error);
    }
  }

  // Delete a bookmark via REST-API (e.g. https://www.dev.stern.de/p-api/lists/bookmarks/items)
  async #deleteBookmark() {
    this.active = false;
    const apiUrl = `/p-api/lists/bookmarks/items/${this.contentId}`;
    try {
      const csrfToken = await this.#getCSRFToken();
      if (!csrfToken) {
        throw new Error("Request failed: CSRF token is undefined.");
      }
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          "X-CSRF-Token": csrfToken,
        },
      });
      if (!response.ok) {
        throw new Error(
          `Request failed: ${response.status} - ${response.statusText}`,
        );
      }
      this.#triggerEvent("toggleBookmark", { action: "delete" });
    } catch (error) {
      // Reset on error
      this.active = true;
      this.#handleError(error);
    }
  }

  // Fetch JSON data
  async #fetchResult(apiUrl: string) {
    try {
      const response = await fetch(apiUrl, {
        headers: { accept: "application/json" },
      });
      if (!response.ok) {
        throw new Error(
          `Request failed: ${response.status} - ${response.statusText}`,
        );
      }
      return await response.json();
    } catch (error) {
      this.#handleError(error);
    }
  }

  // Dispatches appropriate events
  #triggerEvent(event: string, options = {}) {
    this.dispatchEvent(
      new CustomEvent(event, {
        detail: {
          ...options,
          contentId: this.contentId,
          paidCategory: this.paidCategory,
        },
      }),
    );
  }

  // Handle bookmark API request errors
  #handleError(error: unknown) {
    let message: string;
    if (error instanceof Error) {
      message = error.message;
    } else {
      message = String(error);
    }
    console.error(message);
    this.#triggerEvent("error", { message });
  }

  // Set "aria-label" attribute on button element depending on "active" attribute.
  #setButtonAriaLabelAndIconClass(active: boolean) {
    this.#bookmarkButton.setAttribute(
      "aria-label",
      active ? ariaLabelActive : ariaLabelInactive,
    );
    this.#bookmarkIcon.className = active ? iconClassActive : iconClassInactive;
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (name === "disabled" && newValue === null) {
      // re-initialize if "disabled" attribute will be removed
      this.#initialize();
    }

    if (name === "active" && oldValue !== newValue) {
      // update aria-label and icon class
      this.#setButtonAriaLabelAndIconClass(newValue !== null);
    }
  }
}

"customElements" in window &&
  customElements.get("ws-bookmarkbutton") === undefined &&
  customElements.define("ws-bookmarkbutton", BookmarkButton);

declare global {
  interface HTMLElementTagNameMap {
    "ws-bookmarkbutton": BookmarkButton;
  }
}
