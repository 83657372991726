import { safeJsonParse, storageAvailable } from "./utils";

interface StorageValue {
  expires: number;
  value: true;
}

// @see https://gitlab.com/guj-digital/stern/web-user/documentation/-/blob/main/openapi/openapi.yaml
export interface UserStatus {
  categories?: string;
  hashedUserId?: string;
  registrationStatus?: boolean | undefined;
  sessionStatus: "active" | "needsRefresh" | "none";
  trackingStatus?: string;
  type?: string;
  userId?: string;
}

export class User extends HTMLElement {
  #defaultStatus: UserStatus = {
    categories: undefined,
    hashedUserId: undefined,
    registrationStatus: undefined,
    sessionStatus: "none",
    trackingStatus: undefined,
    type: undefined,
    userId: undefined,
  };
  #status?: UserStatus;

  static registrationStatusKey = "ws-user.registration_status";

  get loggedIn(): boolean {
    return this.hasAttribute("logged-in");
  }

  #getRegistrationStatus(): true | undefined {
    if (!storageAvailable("localStorage")) return;
    const value = localStorage.getItem(User.registrationStatusKey);
    if (typeof value !== "string") return;
    const data = safeJsonParse(value, this.#isStorageValue);
    if (typeof data === "undefined") return;
    if (data.expires < Date.now()) {
      localStorage.removeItem(User.registrationStatusKey);
      return;
    }
    return data.value;
  }

  #isStorageValue(data: any): data is StorageValue {
    return (
      typeof data === "object" &&
      typeof data.expires === "number" &&
      typeof data.value === "boolean"
    );
  }

  #isUserStatus(data: any): data is UserStatus {
    return (
      typeof data === "object" &&
      typeof data.sessionStatus === "string" &&
      ["active", "needsRefresh", "none"].includes(data.sessionStatus)
    );
  }

  #setRegistrationStatus(value: true) {
    if (!storageAvailable("localStorage")) return;
    const status: StorageValue = {
      expires: Date.now() + 1000 * 60 * 60 * 24 * 365, // Add a year
      value,
    };
    localStorage.setItem(User.registrationStatusKey, JSON.stringify(status));
  }

  async getStatus(): Promise<UserStatus> {
    if (this.#isUserStatus(this.#status)) {
      return this.#status;
    }

    let userStatus: UserStatus = this.#defaultStatus;

    if (this.loggedIn) {
      const response = await fetch("/p-api/sso/status");
      if (!response.ok) {
        throw new Error("Failed to fetch user status");
      }

      const data = await response.json();
      if (!this.#isUserStatus(data)) {
        throw new Error("Invalid user status response", data);
      }
      userStatus = { ...userStatus, ...data };
    }

    if (["active", "needsRefresh"].includes(userStatus.sessionStatus)) {
      userStatus.registrationStatus = true;
      this.#setRegistrationStatus(true);
    } else {
      userStatus.registrationStatus = this.#getRegistrationStatus();
    }

    this.#status = userStatus;
    return this.#status;
  }
}

customElements.get("ws-user") ?? customElements.define("ws-user", User);

declare global {
  interface HTMLElementTagNameMap {
    "ws-user": User;
  }
}
