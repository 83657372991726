import { GALLERY_CHANGE_EVENT } from "./gallery";
import { getViewport } from "./utils";

/**
 * Nielsen tracking is used for audience measurements like size and behaviour
 */
export class NielsenStatic extends HTMLElement {
  #segA: string;

  get adZone(): string {
    return this.getAttribute("adZone") ?? "";
  }

  get appId(): string {
    return this.getAttribute("appId") ?? "";
  }

  get assetId(): string {
    return this.getAttribute("assetId") ?? "";
  }

  get ivwTopic(): string {
    return this.getAttribute("ivwTopic") ?? "";
  }

  get paidCategory(): string {
    return this.getAttribute("paidCategory") ?? "";
  }

  get section(): string {
    return this.getAttribute("section") ?? "";
  }

  get subCategoryVideo(): string {
    return this.getAttribute("subCategoryVideo") ?? "";
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.#segA = "";
  }

  connectedCallback() {
    this.#segA = this.#createNielsenSegAString(
      this.adZone,
      this.paidCategory,
      this.subCategoryVideo,
      this.ivwTopic,
    );

    const segB = "CPI";
    this.#createNolbundle();
    this.#nSdkInstance = window.NOLBUNDLE.nlsQ(this.appId, "nlsnInstance", {
      //Comment in for debugging
      //nol_sdkDebug: "debug",
    });
    this.#createScript(this.#segA, segB);
    this.#startStaticMeasurement();
    window.addEventListener("pageshow", this.#handlePageShow);
    window.onbeforeunload = () => {
      this.#endStaticMeasurement();
    };

    const galleries = document.querySelectorAll("ws-gallery");
    galleries.forEach((gallery) => {
      gallery.addEventListener(GALLERY_CHANGE_EVENT, () => {
        this.#handleVirtualPageview(this.#segA, "WPI");
      });
    });
  }

  #nSdkInstance!: NSdkInstance;

  #sessionStorageMetadata!: string;

  #createNielsenSegAString(
    adZone: string,
    paidCategory: string,
    subCategoryVideo: string,
    ivwTopic: string,
  ): string {
    const viewportNumber =
      getViewport() === "mobile" || getViewport() === "tablet" ? "2" : "1";
    const isPaidcontent = paidCategory !== "undefined";
    const isVideo = subCategoryVideo === "video";
    const isUserGeneratedContent = false;

    return [
      `${adZone}//`,
      isPaidcontent ? "p" : "f",
      viewportNumber,
      `D${isVideo ? "V" : "B"}${isUserGeneratedContent ? "U" : "R"}-${ivwTopic}`,
    ].join("");
  }

  #createNolbundle() {
    // Add Static Queue Snippet
    // @ts-expect-error - third-party embed code
    // prettier-ignore
    // eslint-disable-next-line
    !function(e,n){function t(e){return"object"==typeof e?JSON.parse(JSON.stringify(e)):e}e[n]=e[n]||{nlsQ:function(o,r,c){var s=e.document,a=s.createElement("script");a.async=1,a.src=("http:"===e.location.protocol?"http:":"https:")+"//cdn-gl.nmrodam.com/conf/"+o+".js#name="+r+"&ns="+n;var i=s.getElementsByTagName("script")[0];return i.parentNode.insertBefore(a,i),e[n][r]=e[n][r]||{g:c||{},ggPM:function(o,c,s,a,i){e[n][r].q=e[n][r].q||[];try{var l=t([o,c,s,a,i]);e[n][r].q.push(l)}catch(e){console&&console.log&&console.log("Error: Cannot register event in Nielsen SDK queue.")}},trackEvent:function(o){e[n][r].te=e[n][r].te||[];try{var c=t(o);e[n][r].te.push(c)}catch(e){console&&console.log&&console.log("Error: Cannot register event in Nielsen SDK queue.")}}},e[n][r]}}}(window,"NOLBUNDLE");
  }

  #createScript(segA: string, segB: string) {
    const script = document.createElement("script");
    const metadataString = `"type":"static", "assetid":"${this.assetId}", "section":"${this.section}", "segA":"${segA}", "segB":"${segB}", "subbrand":"c42"`;
    script.innerHTML = `window.articlemetadata = {${metadataString}}`;
    this.shadowRoot!.appendChild(script);
    sessionStorage.setItem("nielsenMetadata", `{${metadataString}}`);
  }

  #endStaticMeasurement(sessionMetadata?: string) {
    this.#nSdkInstance.ggPM(
      "staticend",
      sessionMetadata
        ? JSON.parse(this.#sessionStorageMetadata)
        : window.articlemetadata,
    );
  }

  #handleVirtualPageview(segA: string, segB: string) {
    this.shadowRoot!.removeChild(this.shadowRoot!.childNodes[0]!); //remove existing script tag inside ws-nielsen
    this.#endStaticMeasurement();
    this.#createScript(segA, segB);
    this.#startStaticMeasurement();
  }

  #handlePageShow = (event: PageTransitionEvent): void => {
    if (event.persisted) {
      this.#sessionStorageMetadata = sessionStorage.getItem("nielsenMetadata")!;
      this.#endStaticMeasurement(this.#sessionStorageMetadata);
      this.#startStaticMeasurement();
    }
  };

  #startStaticMeasurement() {
    this.#nSdkInstance.ggPM("staticstart", window.articlemetadata);
  }

  disconnectedCallback() {
    window.removeEventListener("pageshow", this.#handlePageShow);

    const galleries = document.querySelectorAll("ws-gallery");
    galleries.forEach((gallery) => {
      gallery.removeEventListener(GALLERY_CHANGE_EVENT, () => {
        this.#handleVirtualPageview(this.#segA, "WPI");
      });
    });
  }
}

customElements.get("ws-nielsen") ??
  customElements.define("ws-nielsen", NielsenStatic);

declare global {
  interface HTMLElementTagNameMap {
    "ws-nielsen": NielsenStatic;
  }

  interface NSdkInstance {
    ggPM(arg1: string, arg2: object): void;
  }

  interface ArticleMetadata {
    assetid: string;
    section: string;
    segA: string;
    type: string;
  }

  interface Window {
    NOLBUNDLE: any;
    articlemetadata: ArticleMetadata;
  }
}
