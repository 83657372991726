import { isElementWithViewport } from "./utils.js";

export type FetchedEvent = CustomEvent;

export const FETCH_FETCHED = "fetched";

const template = document.createElement("template");

template.innerHTML = `<slot></slot>`;

export class Fetch extends HTMLElement {
  static observedAttributes = ["disabled"];

  #observer: IntersectionObserver | undefined;

  get disabled(): boolean {
    return this.hasAttribute("disabled");
  }

  // "eager" (default) | "visible"
  get loading(): string {
    return this.getAttribute("loading") ?? "eager";
  }

  // "within" (default) | "self"
  get replace(): string {
    return this.getAttribute("replace") ?? "within";
  }

  get src(): string {
    return this.getAttribute("src") ?? "";
  }

  // "document" (default) | "parent" | "parentViewport"
  get viewport(): string {
    return this.getAttribute("viewport") ?? "document";
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.shadowRoot!.appendChild(template.content.cloneNode(true));
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (name === "disabled" && oldValue === "" && newValue === null) {
      this.#initialize();
    }
  }

  connectedCallback() {
    if (this.disabled) return;
    this.#initialize();
  }

  #getViewport(): Element | null {
    return this.viewport === "parent"
      ? this.parentElement
      : this.viewport === "parentViewport" &&
          isElementWithViewport(this.parentElement)
        ? this.parentElement.getViewportElement()
        : null;
  }

  #initialize() {
    if (this.loading === "visible") {
      this.#observer = new IntersectionObserver(this.#onIntersection, {
        root: this.#getViewport(),
        rootMargin: "200%",
        threshold: 1.0,
      });
      this.#observer.observe(this);
    } else {
      this.#start();
    }
  }

  #onIntersection = (entries: IntersectionObserverEntry[]): void => {
    if (!entries[0]?.isIntersecting) return;
    this.#start();
  };

  #start(): void {
    void this.#fetch().then((html) => {
      this.#replace(html);
      this.#observer?.disconnect();

      const fetchedEvent: FetchedEvent = new CustomEvent(FETCH_FETCHED);
      this.dispatchEvent(fetchedEvent);
    });
  }

  async #fetch(): Promise<string> {
    if (!this.src) return "";
    const response = await window.fetch(this.src);
    if (!response.ok) return "";
    return await response.text();
  }

  #replace(html: string): void {
    switch (this.replace) {
      case "self":
        this.outerHTML = html;
        break;
      default: // "within"
        this.innerHTML = html;
    }
  }

  disconnectedCallback() {
    this.#observer?.disconnect();
  }
}

customElements.get("ws-fetch") ?? customElements.define("ws-fetch", Fetch);

declare global {
  interface HTMLElementTagNameMap {
    "ws-fetch": Fetch;
  }
}
