export class TextToSpeech extends HTMLElement {
  /* Code for TextToSpeech Web Component */
}

customElements.get("ws-texttospeech") ??
  customElements.define("ws-texttospeech", TextToSpeech);

declare global {
  interface HTMLElementTagNameMap {
    "ws-texttospeech": TextToSpeech;
  }
}
