type EventsMap = Record<
  string,
  {
    type: NETID_EVENTS;
    detail?: {
      eventAction: string;
      eventLabel: string;
    };
  }
>;

export const netidEventsArray = [
  "netid_script_loaded",
  "netid_dom_modified",
  "netid_layer_shown",
  "netid_script_notloaded",
  "netid_script_loaded",
  "netid_layer_hidden",
  "netid_layer_clicked",
];

type NETID_EVENTS =
  | "netid_script_loaded"
  | "netid_dom_modified"
  | "netid_layer_shown"
  | "netid_script_notloaded"
  | "netid_script_loaded"
  | "netid_layer_hidden"
  | "netid_layer_clicked";

export type StatusEvent = CustomEvent<{
  eventAction: string;
  eventLabel: string;
}>;

/**
 * Login provider as an alternative for login with Facebook/Google/Apple etc.
 * The tool is a cooperation of different companies, RTL, ProSiebenSat. 1 amongst others.
 * Is not an independent identification but share its information between the connected companies.
 * It offfers the possibility to show customized advertising for logged in users and to circumvent blocked third-party cookies.
 * @see https://www.netid.de/
 */
export class NetId extends HTMLElement {
  #externalToInternalEvents: EventsMap = {
    "netid-accept": {
      type: "netid_layer_clicked",
      detail: {
        eventAction: "layer_action",
        eventLabel: "accept",
      },
    },
    "netid-accepted": {
      type: "netid_script_loaded",
      detail: {
        eventAction: "script_action",
        eventLabel: "accepted",
      },
    },
    "netid-close": {
      type: "netid_layer_clicked",
      detail: {
        eventAction: "layer_action",
        eventLabel: "close",
      },
    },
    "netid-decline": {
      type: "netid_layer_clicked",
      detail: {
        eventAction: "layer_action",
        eventLabel: "decline",
      },
    },
    "netid-dom-modified": {
      type: "netid_dom_modified",
    },
    "netid-hide": {
      type: "netid_layer_hidden",
    },
    "netid-later": {
      type: "netid_layer_clicked",
      detail: {
        eventAction: "layer_action",
        eventLabel: "later",
      },
    },
    "netid-loaded": {
      type: "netid_script_loaded",
      detail: {
        eventAction: "script_action",
        eventLabel: "loaded",
      },
    },
    "netid-show": {
      type: "netid_layer_shown",
    },
    "netid-suppressed": {
      type: "netid_script_notloaded",
    },
  };

  get url() {
    return this.getAttribute("url") ?? "";
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  connectedCallback() {
    this.#executeScripts();
    this.#addEventListeners(Object.keys(this.#externalToInternalEvents));
  }

  #addEventListeners(types: string[]) {
    for (const type of types) {
      window.addEventListener(type, this.#handleExternalEvent);
    }
  }

  #executeScripts() {
    const script = document.createElement("script");
    script.src = this.url;
    script.defer = true;
    this.shadowRoot!.appendChild(script);
  }

  #handleExternalEvent = (externalEvent: Event) => {
    const event = this.#externalToInternalEvents[externalEvent.type];
    if (!event) {
      console.error(`Unknown event: ${externalEvent.type}`);
      return;
    }
    const { type, detail } = event;
    const internalEvent = new CustomEvent(type, { detail });
    this.dispatchEvent(internalEvent);
  };

  #removeEventListeners(types: string[]) {
    for (const type of types) {
      window.removeEventListener(type, this.#handleExternalEvent);
    }
  }

  disconnectedCallback() {
    this.#removeEventListeners(Object.keys(this.#externalToInternalEvents));
  }
}

"customElements" in window &&
  customElements.get("ws-netid") === undefined &&
  customElements.define("ws-netid", NetId);

declare global {
  interface HTMLElementTagNameMap {
    "ws-netid": NetId;
  }
}
