import type { AnalyticsConfig } from "@website/types";
import { getOrientation, getViewport } from "./utils";
import { ChangeEvent, GALLERY_CHANGE_EVENT } from "./gallery";
import { netidEventsArray, StatusEvent } from "./netid";
import {
  NEWSLETTER_SUBSCRIPTION,
  NEWSLETTER_TOPIC_CLICKED,
  NewsletterClickedEvent,
  NewsletterSubscriptionEvent,
} from "./socialwidget";

interface TeaserData {
  event: "teaser_clicked";
  content: {
    brand: string;
    headline: string;
    id: string;
    paidCategory: string;
    teaserType: string;
    type: string;
  };
}

/**
 * This web component, GoogleTagManager, is a custom HTML element designed
 * to integrate Google Tag Manager (GTM) into a web page.
 * @see  https://support.google.com/tagmanager/answer/14847097
 */
export class GoogleTagManager extends HTMLElement {
  get containerId(): string {
    return this.getAttribute("container-id") ?? "";
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  connectedCallback() {
    try {
      this.#executeScripts();
      this.#initialize(this.#defaultPageConfig());

      //Add eventlisteners for virtual pageview in galleries
      const galleries = document.querySelectorAll("ws-gallery");
      galleries.forEach((gallery) => {
        gallery.addEventListener(
          GALLERY_CHANGE_EVENT,
          this.#handleVirtualPageview,
        );
      });

      //Add eventlistener for NetID
      const netid = document.querySelector("ws-netid");
      netidEventsArray.forEach((entry) => {
        netid?.addEventListener(entry, this.#handleNetidEvent);
      });

      //Add eventlisteners for Newsletterform
      const socialWidgets = document.querySelectorAll("ws-socialwidget");

      socialWidgets.forEach((widget) => {
        widget.addEventListener(
          NEWSLETTER_TOPIC_CLICKED,
          this.#handleNewsletterTopicClicked,
        );

        widget.addEventListener(
          NEWSLETTER_SUBSCRIPTION,
          this.#handleNewsletterSubscribed,
        );
      });
    } catch (error) {
      if (error instanceof SyntaxError) {
        console.error("Error parsing Google Tag Manager configuration:", error);
      } else {
        console.error("An unexpected error occurred:", error);
      }
    }
    this.#bindTeasers();
  }

  #executeScripts() {
    const script = document.createElement("script");
    script.textContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${this.containerId}');`;
    this.shadowRoot!.appendChild(script);
  }

  #handleNetidEvent = (e: Event) => {
    const event = e as StatusEvent;
    const details = event.detail
      ? { [event.detail.eventAction]: event.detail.eventLabel }
      : null;
    window.dataLayer.push({
      event: event.type,
      ...details,
    });
  };

  #handleNewsletterTopicClicked = (e: Event) => {
    const event = e as NewsletterClickedEvent;
    window.dataLayer.push({
      event: event.type,
      ...event.detail,
    });
  };

  #handleNewsletterSubscribed = (e: Event) => {
    const event = e as NewsletterSubscriptionEvent;
    window.dataLayer.push({
      event: event.type,
      ...event.detail,
    });
  };

  #handleVirtualPageview = (e: Event) => {
    const event = e as ChangeEvent;
    const { galleryHeadline, itemNumber, itemUrl, totalItems } = event.detail;

    const currentConfig = this.#defaultPageConfig();
    const galleryConfig = {
      ...currentConfig,
      content: {
        ...currentConfig.content,
        virt_path_url: itemUrl,
      },
      gallery: {
        current_picture: itemNumber,
        galleryHeadline: galleryHeadline,
        number_of_pictures: totalItems,
      },
      event: "virt_path",
    };
    window.dataLayer.push(galleryConfig);
  };

  async #initialize(config: AnalyticsConfig) {
    if (!Array.isArray(window.dataLayer)) return;

    // Enhance the configuration with clientside data
    config.content.molten_bundle_site_type = getViewport();
    config.tech.device_orientation = getOrientation();

    // Enhance the configuration with user data
    const user = document.querySelector("ws-user");
    if (user) {
      await window.customElements.whenDefined("ws-user");
      const status = await user.getStatus();
      config.login.distribution_channel = status.type ?? "not_set";
      config.login.hashed_user_id = status.hashedUserId ?? "not_set";
      config.login.product_user_status = status.trackingStatus ?? "not_set";
      config.login.registration_status = status.registrationStatus ?? "not_set";
    }

    window.dataLayer.push(config);
  }

  #defaultPageConfig(): AnalyticsConfig {
    const configScript = this.querySelector(`script[type="application/json"]`);
    if (!configScript) {
      console.warn("Google Tag Manager configuration script not found.");
    }
    return JSON.parse(configScript?.textContent ?? "{}");
  }

  #bindTeasers() {
    // Select all elements that match the attribute "data-trace-id='teaser'"
    const teasers = document.querySelectorAll<HTMLElement>(
      "[data-trace-id='teaser']",
    );

    teasers.forEach((teaser) => {
      // Extract dataset attributes with default empty strings for safety
      const {
        brandIdentifier = "",
        contentId = "",
        headline = "",
        paidCategory = "",
        teaserType = "",
        type = "",
      } = teaser?.dataset as DOMStringMap;
      const teaserLink = teaser.querySelector("a");

      if (!teaserLink) return;

      // Add click event listener for each teaser
      teaserLink.addEventListener("click", () => {
        // Push the teaser click event to the data layer
        this.#pushToDataLayer({
          event: "teaser_clicked",
          content: {
            brand: brandIdentifier,
            headline,
            id: contentId,
            paidCategory,
            teaserType,
            type,
          },
        });
      });
    });
  }

  // Private method to push data to the data layer
  #pushToDataLayer(data: TeaserData): void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
  }

  disconnectedCallback() {
    const galleries = document.querySelectorAll("ws-gallery");
    galleries.forEach((gallery) => {
      gallery.removeEventListener(
        GALLERY_CHANGE_EVENT,
        this.#handleVirtualPageview,
      );
    });
  }
}

customElements.get("ws-gtm") ??
  customElements.define("ws-gtm", GoogleTagManager);

declare global {
  interface HTMLElementTagNameMap {
    "ws-gtm": GoogleTagManager;
  }
  interface Window {
    dataLayer: any[];
  }
}
