export const autonativeConfigIndex = {
  "ems-native-adlabel": ".article-navigation__ad-labels .ad-labels__item",
  "ems-native-overallheadline": ".article-navigation__headline",
  "ems-native-backlink": ".article-navigation__headline-link",
  "ems-native-teaserwrapper": ".group-teaserblock__items",
  "ems-native-teasercontainer": ".teaser",
  "ems-native-teaseradlabel": ".teaser__ad-labels .ad-labels__item",
  "ems-native-teasersubheadline": ".teaser__kicker",
  "ems-native-teaserheadline": ".teaser__headline",
  "ems-native-teaserimage": ".teaser__image .image",
  "ems-native-teaserlink": ".teaser__link",
  "ems-native-teasertext": ".teaser__text",
};

export const autonativeConfigArticle = {
  "ems-native-adlabel": ".article-navigation__ad-labels .ad-labels__item",
  "ems-native-overallheadline": [
    ".article-navigation__headline-link",
    ".group-teaserlist__headline-link",
  ],
  "ems-native-backlink": [
    ".article-navigation__headline-link",
    ".group-teaserlist__headline-link",
  ],
  "ems-native-subheadline": ".title__kicker",
  "ems-native-headline": ".title__headline",
  "ems-native-mainimage": ".article__lead-content .image-element__image",
  "ems-native-imagetext": ".article__lead-content .image-element__description",
  "ems-native-copyright": ".article__lead-content .image-element__credits",
  "ems-native-headlinetext": ".typo-intro",
  "ems-native-articletext": ".text-element p",
  "ems-native-teaserwrapper":
    ".group-teaserlist--special-relateds .group-teaserlist__items",
  "ems-native-teasercontainer": ".teaser",
  "ems-native-teaseradlabel": ".teaser__ad-labels .ad-labels__item",
  "ems-native-teasersubheadline": ".teaser__kicker",
  "ems-native-teaserheadline": ".teaser__headline",
  "ems-native-teaserimage": ".teaser__image .image",
  "ems-native-teaserlink": ".teaser__link",
  "ems-native-teasertext": ".teaser__text",
  "ems-native-special-elements": [
    ".sub-navigation",
    ".group-teaserlist--special-relateds",
  ],
  "ems-native-iframe-hide": [".article__header", ".article__lead-content"],
};

/**
 * Autonative Mapping Script
 * https://help.adalliance.io/home/autonative/
 */
export class AutoNative extends HTMLElement {
  get contentType(): string {
    return this.getAttribute("content-type") ?? "";
  }

  // Check if current page is requested as autonative.
  isActive = new URLSearchParams(window.location.search).has("an");

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  #executeScripts() {
    if (!this.isActive) {
      return;
    }

    let config;
    if (this.contentType === "index") {
      config = autonativeConfigIndex;
    } else {
      config = autonativeConfigArticle;
    }

    window.autoNative = window.autoNative || { mapping: {} };
    window.autoNative.mapping = config;
  }

  connectedCallback() {
    this.#executeScripts();
  }
}

"customElements" in window &&
  customElements.get("ws-autonative") === undefined &&
  customElements.define("ws-autonative", AutoNative);

declare global {
  interface HTMLElementTagNameMap {
    "ws-autonative": AutoNative;
  }
  interface Window {
    autoNative: any;
  }
}
