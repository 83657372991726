import { getViewport } from "./utils";

export const scriptUrl = {
  bottom: {
    live: "/stern.de/MoltenBundle.bottom.js",
    preview:
      "https://adctrl.emsmobile.de/molten/preview/stern/MoltenBundle.bottom.js",
  },
  top: {
    live: "/stern.de/MoltenBundle.top.js",
    preview:
      "https://adctrl.emsmobile.de/molten/preview/stern/MoltenBundle.top.js",
  },
};

/**
 * Advertising library from the AdAlliance, named MoltenBundle
 * @see https://help.adalliance.io/home/tagcontrol/
 */
export class AdScript extends HTMLElement {
  get contentType(): string {
    return this.getAttribute("content-type") ?? "";
  }

  get isIndexPage(): boolean {
    return this.getAttribute("is-index-page") === "true";
  }

  get keywords(): string[] {
    return JSON.parse(this.getAttribute("keywords") ?? "[]");
  }

  get name(): string {
    return this.getAttribute("name") ?? "";
  }

  get zone(): string {
    return this.getAttribute("zone") ?? "";
  }

  get userType(): string {
    return this.getAttribute("user-type") ?? "";
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  #executeBottomScripts() {
    this.#loadScript(
      this.#isPreview() ? scriptUrl.bottom.preview : scriptUrl.bottom.live,
    );
    if (!Array.isArray(window.MoltenBundle?.cmd)) return;
    window.MoltenBundle.cmd.push(() => {
      window.MoltenBundle.finalize();
    });
  }

  #executeScripts() {
    switch (this.name) {
      case "bottom":
        this.#executeBottomScripts();
        break;
      case "top":
        this.#executeTopScripts();
        break;
      default:
        console.log(`Unknown adscript name: ${this.name}`);
        break;
    }
  }

  #executeTopScripts() {
    this.#loadScript(
      this.#isPreview() ? scriptUrl.top.preview : scriptUrl.top.live,
    );
    window.MoltenBundle = window.MoltenBundle || {};
    window.MoltenBundle.cmd = window.MoltenBundle.cmd || [];
    window.MoltenBundle.cmd.push(() => {
      window.MoltenBundle.getClient().setSiteType(getViewport());
      window.MoltenBundle.getClient().setZone(this.zone);
      window.MoltenBundle.getClient().setIsIndexPage(this.isIndexPage);
      window.MoltenBundle.getClient().setKeywords(this.keywords);
      window.MoltenBundle.getClient().addTargetingParameter(
        "ct",
        this.contentType,
      );
      if (this.userType && this.userType === "plus") {
        window.MoltenBundle.getClient().addTargetingParameter("ut", "plus");
      }
    });
  }

  #isPreview(): boolean {
    return window.location.search.includes("ada=1");
  }

  #loadScript(src: string) {
    const script = document.createElement("script");
    script.src = src;
    script.async = true;
    this.shadowRoot!.appendChild(script);
  }

  connectedCallback() {
    this.#executeScripts();
  }
}

"customElements" in window &&
  customElements.get("ws-adscript") === undefined &&
  customElements.define("ws-adscript", AdScript);

declare global {
  interface HTMLElementTagNameMap {
    "ws-adscript": AdScript;
  }
  interface Window {
    MoltenBundle: any;
  }
}
