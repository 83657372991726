import type { DeviceOrientation, Viewport } from "@website/types";

interface ElementWithViewport extends HTMLElement {
  getViewportElement(): Element;
}
type InteractionObserverCallback = () => void;

export function getOrientation(): DeviceOrientation {
  return (
    "matchMedia" in window
      ? window.matchMedia("(orientation: portrait)").matches
      : document.documentElement.clientWidth <=
        document.documentElement.clientHeight
  )
    ? "portrait"
    : "landscape";
}

export function getViewport(): Viewport {
  const viewport = {
    xs: 0,
    md: 768,
    lg: 1024,
    xl: 1440,
  };

  function getOrientation(): "landscape" | "portrait" {
    if (window.screen && window.screen.orientation) {
      return window.screen.orientation.type.includes("landscape")
        ? "landscape"
        : "portrait";
    }
    // this is a fallback for Safari 16.0-16.3
    // please remove the following three lines after Safari 18 is released (since we support the last 2 major versions)
    if ("orientation" in window) {
      return Math.abs(window.orientation) === 90 ? "landscape" : "portrait";
    }

    return (window as Window).innerWidth > (window as Window).innerHeight
      ? "landscape"
      : "portrait";
  }

  function isMobile(): boolean {
    const height: number = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0,
    );
    const width: number = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0,
    );

    return height < viewport.lg && width < viewport.lg;
  }

  const viewportWidth: number =
    isMobile() && getOrientation() === "landscape"
      ? Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
      : Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

  return viewportWidth <= viewport.md
    ? "mobile"
    : viewportWidth <= viewport.lg
      ? "tablet"
      : viewportWidth <= viewport.xl
        ? "desktop"
        : "widescreen";
}

export class InteractionObserver {
  #callback: InteractionObserverCallback;
  #events: string[] = ["click", "keydown", "mousemove", "scroll", "touchstart"];
  #targets: Element[] = [];

  constructor(callback: InteractionObserverCallback) {
    this.#callback = callback;
  }

  #handleEvents = () => {
    this.#callback();
  };

  #removeEventListeners(target: Element) {
    for (const event of this.#events) {
      target.removeEventListener(event, this.#handleEvents);
    }
  }

  observe(target: Element) {
    for (const event of this.#events) {
      target.addEventListener(event, this.#handleEvents);
    }
    this.#targets.push(target);
  }

  unobserve(target: Element) {
    const index = this.#targets.indexOf(target);
    if (index === -1) return;
    this.#removeEventListeners(target);
    this.#targets.splice(index, 1);
  }

  disconnect() {
    for (const target of this.#targets) {
      this.#removeEventListeners(target);
    }
  }
}

export function isElementWithViewport(
  element: Element | null,
): element is ElementWithViewport {
  return (
    element instanceof Element &&
    "getViewportElement" in element &&
    typeof element.getViewportElement === "function"
  );
}

/**
 * Checks if the website is opened in a native app.
 * This is determined by the `viewMode` query parameter or the user agent (legacy app).
 */
export function isInApp(search: string, userAgent: string): boolean {
  const params = new URLSearchParams(search);
  const app = params.get("viewMode") === "app";
  const legacyApp = /(app|pwa)_(and|ios)/.test(userAgent);
  return app || legacyApp;
}

export function safeJsonParse<T>(
  text: string,
  typeGuard: (obj: any) => obj is T,
): T | undefined {
  try {
    const parsed = JSON.parse(text);
    return typeGuard(parsed) ? (parsed as T) : undefined;
  } catch (error) {
    console.error("Failed to parse JSON ", error);
    return undefined;
  }
}

export function storageAvailable(
  type: "localStorage" | "sessionStorage",
): boolean {
  try {
    const storage = window[type];
    const testKey = "__test__";
    storage.setItem(testKey, testKey);
    storage.removeItem(testKey);
    return true;
  } catch (error) {
    console.error(`Storage checking for '${type}' failed `, error);
    return false;
  }
}

export class TimeoutError extends Error {
  timeout: number;

  constructor(message: string, timeout: number) {
    super(message);
    this.name = "TimeoutError";
    this.timeout = timeout;
  }
}

export async function waitUntil(
  predicate: () => boolean,
  options: { timeout?: number; interval?: number },
) {
  const timeout: number = options.timeout ?? 5000;
  const interval: number = options.interval ?? 100;
  let duration = 0;

  return new Promise<{ duration: number }>((resolve, reject) => {
    if (predicate()) {
      resolve({ duration });
      return;
    }

    const intervalId = window.setInterval(() => {
      duration += interval;
      if (predicate()) {
        clearInterval(intervalId);
        resolve({ duration });
      }
    }, interval);

    window.setTimeout(() => {
      clearInterval(intervalId);
      reject(new TimeoutError("Operation timed out", timeout));
    }, timeout);
  });
}

export const supportsResizeObserver = "ResizeObserver" in window;

// Polyfills "scrollend" event in safari
export function polyfillScrollendEvent(element: Element) {
  const scrollendEvent = new Event("scrollend");
  let scrollEndTimer: number;

  element.addEventListener("scroll", () => {
    window.clearTimeout(scrollEndTimer);
    scrollEndTimer = window.setTimeout(
      () => element.dispatchEvent(scrollendEvent),
      100,
    );
  });
}
