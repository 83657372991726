export class PaywallControl extends HTMLElement {
  connectedCallback() {
    const restrictedElement = this.querySelector(".ws-open-dialog"),
      dialog = document.querySelector("dialog");

    if (restrictedElement && dialog) {
      restrictedElement.addEventListener("click", function () {
        dialog.showModal();
      });
    }
  }
}

"customElements" in window &&
  customElements.get("ws-paywallcontrol") === undefined &&
  customElements.define("ws-paywallcontrol", PaywallControl);

declare global {
  interface HTMLElementTagNameMap {
    "ws-paywallcontrol": PaywallControl;
  }
}
