/**
 * CSRF token for API requests
 * https://rtltech.atlassian.net/wiki/spaces/PAID/pages/212439411/05+Architektur+-+CSRF-Token
 */
export class Token extends HTMLElement {
  #cache: { expires?: number; value?: string } = {};

  get token(): Promise<string | undefined> {
    return this.#getToken();
  }

  get section(): string {
    return this.getAttribute("section") || "";
  }

  get ttl(): number {
    return Number(this.getAttribute("ttl")) || 3600;
  }

  get url(): string {
    return this.getAttribute("url") || "/p-api/csrf/";
  }

  async #getToken() {
    let token = this.#getValidTokenFromCache();
    if (typeof token === "undefined") {
      const tokenUrl = this.url + this.section;
      const response = await this.#fetchToken(tokenUrl);
      if (response?.token) {
        token = response.token;
        this.#cacheToken(response.token);
      }
    }
    return token;
  }

  #cacheToken(token: string) {
    this.#cache.expires = Date.now() + this.ttl * 1000;
    this.#cache.value = token;
  }

  #getValidTokenFromCache() {
    if (this.#cache?.value && this.#cache?.expires) {
      const isValid = Date.now() < this.#cache.expires;
      if (isValid) {
        return this.#cache.value;
      }
    }
    return undefined;
  }

  async #fetchToken(tokenUrl: string, retries = 1): Promise<any> {
    return fetch(tokenUrl, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          if (retries > 0) {
            return this.#fetchToken(tokenUrl, retries - 1);
          }
          throw new Error(`Status: ${response.status}`);
        }
        return response.json();
      })
      .catch((error) => {
        console.error(`Error while fetching token: ${error.message}`);
      });
  }
}

"customElements" in window &&
  customElements.get("ws-token") === undefined &&
  customElements.define("ws-token", Token);

declare global {
  interface HTMLElementTagNameMap {
    "ws-token": Token;
  }
}
