import { isInApp, TimeoutError, waitUntil } from "../src/utils.js";

/**
 * Consent interface for Sourcepoint implementation
 * @see https://sourcepoint.com/
 */
export class Sourcepoint extends HTMLElement {
  #tcString: string | undefined;
  #promiseResolver!: (value: PromiseLike<unknown> | unknown) => void;
  #tcStringPromise: Promise<unknown>;

  get baseEndpoint() {
    return this.getAttribute("base-endpoint") ?? "";
  }

  get privacyManagerId() {
    return this.getAttribute("privacy-manager-id") ?? "";
  }

  get propertyHref() {
    return this.getAttribute("property-href");
  }

  get isPURSubscriber(): boolean {
    return this.hasAttribute("pur-rights");
  }

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.#tcStringPromise = new Promise((resolve) => {
      this.#promiseResolver = resolve;
    });
  }

  connectedCallback() {
    if (!navigator.cookieEnabled) return; // Do not load consent for bots
    this.#executeScripts();
    this.#init();
  }

  #executeScripts() {
    const stubScript = document.createElement("script");
    stubScript.textContent = `"use strict";function _typeof(t){return(_typeof="function"==typeof Symbol&&"symbol"==typeof Symbol.iterator?function(t){return typeof t}:function(t){return t&&"function"==typeof Symbol&&t.constructor===Symbol&&t!==Symbol.prototype?"symbol":typeof t})(t)}!function(){var t=function(){var t,e,o=[],n=window,r=n;for(;r;){try{if(r.frames.__tcfapiLocator){t=r;break}}catch(t){}if(r===n.top)break;r=r.parent}t||(!function t(){var e=n.document,o=!!n.frames.__tcfapiLocator;if(!o)if(e.body){var r=e.createElement("iframe");r.style.cssText="display:none",r.name="__tcfapiLocator",e.body.appendChild(r)}else setTimeout(t,5);return!o}(),n.__tcfapi=function(){for(var t=arguments.length,n=new Array(t),r=0;r<t;r++)n[r]=arguments[r];if(!n.length)return o;"setGdprApplies"===n[0]?n.length>3&&2===parseInt(n[1],10)&&"boolean"==typeof n[3]&&(e=n[3],"function"==typeof n[2]&&n[2]("set",!0)):"ping"===n[0]?"function"==typeof n[2]&&n[2]({gdprApplies:e,cmpLoaded:!1,cmpStatus:"stub"}):o.push(n)},n.addEventListener("message",(function(t){var e="string"==typeof t.data,o={};if(e)try{o=JSON.parse(t.data)}catch(t){}else o=t.data;var n="object"===_typeof(o)&&null!==o?o.__tcfapiCall:null;n&&window.__tcfapi(n.command,n.version,(function(o,r){var a={__tcfapiReturn:{returnValue:o,success:r,callId:n.callId}};t&&t.source&&t.source.postMessage&&t.source.postMessage(e?JSON.stringify(a):a,"*")}),n.parameter)}),!1))};"undefined"!=typeof module?module.exports=t:t()}();`;
    this.shadowRoot!.appendChild(stubScript);

    // Client configuration script
    window._sp_queue = [];
    window._sp_ = {
      config: {
        accountId: 212,
        baseEndpoint: this.baseEndpoint,
        gdpr: {},
        targetingParams: {
          isInApp: isInApp(window.location.search, window.navigator.userAgent),
          isPURSubscriber: this.isPURSubscriber,
        },
      },
    };

    // localhost only - maps the implementation to a specific URL as set up in the Sourcepoint account dashboard
    if (this.propertyHref) {
      window._sp_.config.propertyHref = this.propertyHref;
      window._sp_.config.joinHref = true;
    }

    const libraryScript = document.createElement("script");
    libraryScript.src = `${this.baseEndpoint}/unified/wrapperMessagingWithoutDetection.js`;
    libraryScript.async = true;
    this.shadowRoot!.appendChild(libraryScript);
  }

  getTcString() {
    // Return a promise that resolves only after `#tcString` is available.
    return new Promise((resolve) => {
      if (this.#tcString) {
        resolve(this.#tcString);
      } else {
        this.#tcStringPromise.then(() => {
          resolve(this.#tcString);
        });
      }
    });
  }

  async #init() {
    let consentedToAll: boolean;

    window.__tcfapi(
      "addEventListener",
      2,
      (
        tcData: {
          tcString: string;
          eventStatus: string;
        },
        success: boolean,
      ) => {
        if (!success) {
          return;
        }

        // Resolve the promise only when "tcloaded" or "useractioncomplete" occurs
        if (
          tcData.eventStatus === "tcloaded" ||
          tcData.eventStatus === "useractioncomplete"
        ) {
          this.#tcString = tcData.tcString;
          this.#promiseResolver(this.#tcString); // Resolve the promise
        }
      },
    );

    try {
      await waitUntil(
        () => typeof window._sp_.addEventListener === "function",
        {
          timeout: 10000,
        },
      );
      window._sp_.addEventListener(
        "onConsentReady",
        (
          message_type: string,
          consentUUID: string,
          euconsent: string,
          info: {
            addtlConsent: string;
            applies: boolean;
            consentedToAll: boolean;
          },
        ) => {
          if (
            typeof consentedToAll === "boolean" &&
            consentedToAll !== info.consentedToAll
          ) {
            window.location.reload();
          } else {
            consentedToAll = info.consentedToAll;
          }
        },
      );
    } catch (error) {
      if (error instanceof TimeoutError) {
        console.error(
          `window._sp_.addEventListener is not available after ${error.timeout}ms`,
        );
      } else {
        console.error("An unexpected error occurred:", error);
      }
    }
  }

  openModal() {
    window._sp_.gdpr.loadPrivacyManagerModal(this.privacyManagerId, "purposes");
  }
}

customElements.get("ws-sourcepoint") ??
  customElements.define("ws-sourcepoint", Sourcepoint);

declare global {
  interface HTMLElementTagNameMap {
    "ws-sourcepoint": Sourcepoint;
  }
  interface Window {
    _sp_: any;
    _sp_queue: any[];
    __tcfapi: any;
  }
}
