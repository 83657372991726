/**
 * Recommendation platform that displays ads and related articles.
 * @see https://developer.outbrain.com/standard-js-detailed-implementation-guide/
 */

export class Outbrain extends HTMLElement {
  #ar1SubTypes = ["audio", "opulent", "video", ""];

  get url(): string {
    return this.getAttribute("url") ?? "";
  }

  get type(): string {
    return this.getAttribute("type") ?? "";
  }

  get subType(): string {
    return this.getAttribute("subtype") ?? "";
  }

  constructor() {
    super();
  }

  connectedCallback() {
    this.#createDiv();
    this.#executeScripts();
  }

  #createDiv() {
    const div = document.createElement("div");
    div.setAttribute("data-src", this.url);
    div.setAttribute(
      "data-widget-id",
      this.#getWidgetId(this.type, this.subType),
    );
    div.setAttribute("class", "OUTBRAIN");
    this.appendChild(div);
  }

  #getWidgetId(type: string, subType: string) {
    if (type === "article" && this.#ar1SubTypes.includes(subType)) {
      return "AR_1";
    } else if (type === "gallery") {
      return "AR_2";
    } else {
      return "AR_30";
    }
  }

  #executeScripts() {
    const script = document.createElement("script");
    script.src = "https://widgets.outbrain.com/outbrain.js";
    script.async = true;
    script.type = "text/javascript";
    this.appendChild(script);
  }
}

customElements.get("ws-outbrain") ??
  customElements.define("ws-outbrain", Outbrain);

declare global {
  interface HTMLElementTagNameMap {
    "ws-outbrain": Outbrain;
  }
}
