export default class Bookmarklist extends HTMLElement {
  #bookmarkRoute = "/p-api/lists/bookmarks/items";

  static get observedAttributes(): string[] {
    return ["disabled"];
  }

  get paidCategory(): string | null {
    return this.getAttribute("paid-category");
  }

  get errorPage(): string | null {
    return this.getAttribute("error-page");
  }

  get disabled(): boolean {
    return this.hasAttribute("disabled");
  }

  constructor() {
    super();

    // Add styles to the shadow DOM
    const style = document.createElement("style");
    style.innerHTML = `
      ws-bookmarklist {
        display: block;
      }
    `;
    document.head.appendChild(style); // Directly append to shadowRoot
  }

  connectedCallback(): void {
    this.#initialize();
  }

  #initialize(): void {
    if (!this.disabled) {
      this.#load();
    }
  }

  async #load(): Promise<void> {
    this.dispatchEvent(new CustomEvent("loading"));
    const contentIds = await this.#getContentIds();
    if (contentIds) {
      const result = await this.#fetchBookmarkListFragment(contentIds).catch(
        (error: Error) => {
          this.#handleError(error.message);
        },
      );
      // Handle success
      if (result) {
        const children = this.#replace(result);
        this.dispatchEvent(new CustomEvent("loaded", { detail: { children } }));
      }
    }
  }

  async #getContentIds(): Promise<string | undefined> {
    const requestUrl = this.paidCategory
      ? `${this.#bookmarkRoute}?paidCategory=${this.paidCategory}`
      : this.#bookmarkRoute;
    const result = await this.#fetchBookmarkListItems(requestUrl).catch(
      (error: Error) => {
        this.#handleError(error.message);
      },
    );
    if (!result) {
      return;
    }
    if (result.items?.length > 0) {
      const contentIds = result.items.map(
        (item: { contentId: string }) => item.contentId,
      );
      return contentIds.join();
    } else {
      this.#handleError("Keine Einträge vorhanden.");
    }
  }

  async #fetchBookmarkListItems(apiUrl: string): Promise<any> {
    const response = await fetch(apiUrl, {
      headers: { accept: "application/json" },
    });
    if (!response.ok) {
      throw new Error("Ein Fehler ist aufgetreten.");
    }
    return await response.json();
  }

  async #fetchBookmarkListFragment(contentIds: string): Promise<string> {
    const requestUrl = `/?partial=search&has_bookmarklist=1&contentId=${contentIds}`;
    const response = await fetch(requestUrl);
    if (!response.ok) {
      throw new Error("Ein Fehler ist aufgetreten.");
    }
    return await response.text();
  }

  #handleError(message: string): void {
    if (this.errorPage) {
      location.href = this.errorPage;
    } else {
      const htmlString = `<p>${message}</p>`;
      const children = this.#replace(htmlString);
      this.dispatchEvent(new CustomEvent("loaded", { detail: { children } }));
    }
  }

  #replace(html: string): Element[] {
    const dom = document.createRange().createContextualFragment(html);
    const children = Array.from(dom.children);
    this.innerHTML = ""; // Clear existing content in the shadow DOM
    this.appendChild(dom);
    return children;
  }

  attributeChangedCallback(
    name: string,
    oldValue: string | null,
    newValue: string | null,
  ): void {
    if (name === "disabled" && newValue === null) {
      this.#initialize();
    }
  }
}

customElements.get("ws-bookmarklist") ??
  customElements.define("ws-bookmarklist", Bookmarklist);

declare global {
  interface HTMLElementTagNameMap {
    "ws-bookmarklist": Bookmarklist;
  }
}
