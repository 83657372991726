import {
  BaseMediaConfig,
  type ClientAndScope,
  Environment,
  GoogleAnalytics4MediaPluginConfig,
  GoogleAnalytics4PluginConfig,
  type HeartbeatMediaPluginConfig,
  type HeartbeatPluginConfig,
  type LinearAdvertisingSlotConfig,
  ManifestConfig,
  type MediaConfig,
  SourceType,
} from "@foundation-player/loader";
import {
  createPlayerUI,
  type PlayerLoaderConfig,
  type UIConfig,
} from "@foundation-player/ui";

interface TrackingData {
  firstPublicationDate?: string;
  hiddenSources?: string[][];
  sources?: string[][];
  staticTitle?: string;
  technicalTags?: string[];
}

export type InitEvent = CustomEvent;

export const INIT = "initialize";

interface Source {
  priority: string;
  url: string;
}

interface ManifestSources {
  type: string | undefined;
  sources: Source[];
}

type Mode = "audio" | "video";

function parseDuration(duration: string): number {
  if (!duration.startsWith("PT")) {
    throw new Error("Invalid duration format");
  }
  const regex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
  const matches = duration.match(regex);
  if (!matches) {
    throw new Error("Invalid duration format");
  }
  const [_, h, m, s] = matches;
  return (
    parseInt(h || "0") * 3600 + parseInt(m || "0") * 60 + parseInt(s || "0")
  );
}

function createAdCallUrl(
  offset: number,
  targeting: string,
  durationInSeconds: number,
  zone: string,
  userPlus: boolean,
): string {
  const adCallUrl = new URL("https://ad-ipd.sxp.smartclip.net/select");
  const vdcParameter = new URLSearchParams(targeting);

  adCallUrl.searchParams.set("ang_conlen", durationInSeconds.toString());
  adCallUrl.searchParams.set("ang_ref", window.location.href);
  adCallUrl.searchParams.set(
    "fwd_cf",
    durationInSeconds < 600 ? "short_form" : "long_form",
  );
  adCallUrl.searchParams.set("fwd_vpaid", "1");
  adCallUrl.searchParams.set("rnd", new Date().getTime().toString());
  adCallUrl.searchParams.set("s", `ems_stern${zone ? "/" + zone : ""}`);
  adCallUrl.searchParams.set("sz", offset === 0 ? "pre" : "post");
  adCallUrl.searchParams.set("type", "vast4");

  vdcParameter.forEach((value, key) => {
    adCallUrl.searchParams.set(key, value);
  });

  if (userPlus) {
    adCallUrl.searchParams.set("fwd_sternplus", "1");
  }

  let urlString = adCallUrl.href;
  urlString += "&ang_plrh={PLAYER_HEIGHT}";
  urlString += "&ang_plrw={PLAYER_WIDTH}";
  urlString += "&consent={CONSENT}";
  urlString += "&fwd_adb=[BLOCKED]";

  return urlString;
}

export class MediaPlayer extends HTMLElement {
  #mediaConfig!: MediaConfig;
  #loaderConfig!: PlayerLoaderConfig & ClientAndScope;
  #uiConfig!: UIConfig;

  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }

  get environment() {
    return this.getAttribute("environment") as Environment;
  }

  get renditions(): Record<string, Source> {
    const renditions = this.getAttribute("renditions");
    if (renditions) {
      try {
        return JSON.parse(renditions);
      } catch (e) {
        console.error("Error parsing renditions attribute:", e);
      }
    }
    return {};
  }

  get tracking(): TrackingData {
    const tracking = this.getAttribute("tracking");
    if (tracking) {
      try {
        return JSON.parse(tracking);
      } catch (e) {
        console.error("Error parsing tracking attribute:", e);
      }
    }
    return {};
  }

  get externalId(): string {
    return this.getAttribute("id") || "";
  }

  get headline(): string {
    return this.getAttribute("headline") || "";
  }

  get kicker(): string {
    return this.getAttribute("kicker") || "";
  }

  get poster(): string {
    return this.getAttribute("poster") || "";
  }

  get mode(): Mode {
    const mode = this.getAttribute("mode");
    if (mode === "audio" || mode === "video") {
      return mode as Mode;
    }
    throw new Error("Attribute 'mode' must be either 'audio' or 'video'");
  }

  get subtype(): string {
    return this.getAttribute("subtype") || "";
  }

  get length(): number {
    if (this.hasAttribute("length")) {
      return parseDuration(this.getAttribute("length")!);
    } else {
      throw new Error("No length value set");
    }
  }

  get zone(): string {
    return this.getAttribute("zone") || "";
  }

  get userPlus(): boolean {
    return this.hasAttribute("user-plus");
  }

  get userNoad(): boolean {
    return this.hasAttribute("user-noad");
  }

  get restricted(): boolean {
    return this.hasAttribute("restricted");
  }

  #getGoogleAnalyticsConfig() {
    const trackingData = this.tracking as TrackingData;
    const googleAnalytics4Config: GoogleAnalytics4MediaPluginConfig = {
      contentType: this.mode,
      firstHeadline: trackingData.staticTitle,
      headline: this.headline,
      id: this.externalId,
      lastPublicationDate: trackingData.firstPublicationDate,
    };

    if (trackingData.technicalTags) {
      googleAnalytics4Config.tags = trackingData.technicalTags;
    }

    if (trackingData.hiddenSources) {
      googleAnalytics4Config.editorialSources = trackingData.hiddenSources.map(
        (sources: string[]) => sources.join(),
      );
    }

    return googleAnalytics4Config;
  }

  connectedCallback() {
    this.#loaderConfig = {
      client: "rtldigitalnews",
      scope: "stern",
      environment: this.environment,
    };
    const isVideo = this.mode === "video";
    this.#loaderConfig.pluginConfigs = {
      "foundation.plugin.visibility": {
        enabled: isVideo,
        pauseAds: isVideo,
        pauseContent: isVideo,
      },
      "foundation.plugin.googleAnalytics4": {
        enabled: true,
        progressTrackingIntervalInSeconds: 10,
      },
      "foundation.plugin.advertising": {
        enabled: true,
        smartclipConfig: {
          adReinsertion: {
            homad: {
              enabled: true,
              setup: {
                clientConfig: {
                  alias: "desternadsplayerfoundation",
                  config:
                    "https://hgc-cf-cache-1.svonm.com/www.stern.de/config.json",
                  enabled: true,
                  server: [
                    "https://ssl.1.damoh.aws.stern.de/[hash]/",
                    "https://ssl.2.damoh.aws.stern.de/[hash]/",
                    "https://ssl.3.damoh.aws.stern.de/[hash]/",
                  ],
                },
                globalConfig:
                  "https://s3.amazonaws.com/homad-global-configs.schneevonmorgen.com/global_config.json",
              },
            },
          },
        },
      },
      "foundation.plugin.heartbeat": {
        enabled: true,
        endpoint: {
          vod: "https://px1.vtrtl.de/vt/hb.do",
          live: "https://px1.vtrtl.de/vtl/hb.do",
          event: "https://px1.vtrtl.de/vtl/hb.do",
        },
        isKidsProfile: false,
        offer: "rtlpweb",
        userStatus: this.userNoad ? 5 : 3,
        payStatus: this.restricted ? 11 : 10,
        videoService: "vms",
      } as HeartbeatPluginConfig,
    };
    this.#uiConfig = {
      allowNativeVideoFullscreen: true,
      headline: this.headline,
      mode: this.mode,
      poster: this.poster,
      secondaryHeadline: this.kicker,
    } as UIConfig & { mode: Mode };
    this.#initializeMedia();
    this.addEventListener(INIT, this.#cleanUpElements);
  }

  disconnectedCallback() {
    this.removeEventListener(INIT, this.#cleanUpElements);
  }

  #getMappedType(type: string): string | undefined {
    return {
      hls: "hlsfairplayhd",
      dash: "dashhd",
      progressive: "progressive",
    }[type];
  }

  async #getMediaConfig(): Promise<MediaConfig> {
    const manifestSources: ManifestSources[] = Object.entries(this.renditions)
      .map(([key, value]) => {
        const manifestType = this.#getMappedType(key);
        return manifestType
          ? {
              type: manifestType,
              sources: [{ url: value.url, priority: "main" }],
            }
          : null;
      })
      .filter(Boolean) as ManifestSources[];

    const googleAnalyticsConfig = this.#getGoogleAnalyticsConfig();
    const heartbeatConfig: HeartbeatMediaPluginConfig = {
      id: "1012674",
      ivw: "/developer/default",
      startType: 0,
    };

    const mediaConfig = {
      type: (this.mode === "video" ? "vod" : "podcast") as SourceType,
      manifests: manifestSources,
      id: this.externalId,
      plugins: {
        "foundation.plugin.googleAnalytics4": googleAnalyticsConfig,
        "foundation.plugin.heartbeat": heartbeatConfig,
      },
    } as BaseMediaConfig & ManifestConfig & GoogleAnalytics4PluginConfig;

    if (
      this.mode === "video" &&
      this.length > 30 &&
      !this.userNoad &&
      this.zone !== "_noad"
    ) {
      await this.#configureAdvertising(mediaConfig);
    }
    return mediaConfig;
  }

  async #configureAdvertising(mediaConfig: MediaConfig) {
    const adSlots: LinearAdvertisingSlotConfig[] = [];

    const setupAds = (targeting: string) => {
      adSlots.push(
        {
          type: "LINEAR",
          offset: 0,
          adCall: createAdCallUrl(
            0,
            targeting,
            this.length,
            this.zone,
            this.userPlus,
          ),
        },
        {
          type: "LINEAR",
          offset: -1,
          adCall: createAdCallUrl(
            -1,
            targeting,
            this.length,
            this.zone,
            this.userPlus,
          ),
        },
      );
    };

    const defaultTargetParams = "&fwd_j4=1:2&fwd_j5=2:3:4";

    const getSXPKeysWithTimeout = (): Promise<string> => {
      return new Promise((resolve) => {
        const timeout = setTimeout(() => {
          resolve(defaultTargetParams);
        }, 3000);

        window.MoltenBundle.Video.getSXPKeysAsync()
          .then((targeting: string) => {
            clearTimeout(timeout);
            resolve(targeting);
          })
          .catch(() => {
            clearTimeout(timeout);
            resolve(defaultTargetParams);
          });
      });
    };

    if (window.MoltenBundle) {
      if (window.MoltenBundle.Video) {
        window.MoltenBundle.Video.setTargeting("vcd", this.length);
        const targeting = await getSXPKeysWithTimeout();
        setupAds(targeting);
      } else {
        await new Promise<void>((resolve) => {
          window.addEventListener("AC_ready", async () => {
            window.MoltenBundle.Video.setTargeting("vcd", this.length);
            const targeting = await getSXPKeysWithTimeout();
            setupAds(targeting);
            resolve();
          });
        });
      }
    }

    mediaConfig.advertising = { slots: adSlots };
  }

  #cleanUpElements() {
    this.querySelector("figure")?.remove();
  }

  async #initializeMedia() {
    this.#mediaConfig = await this.#getMediaConfig();
    this.#createPlayerUIAndLoadMedia();
  }

  async #createPlayerUIAndLoadMedia() {
    try {
      if (this.shadowRoot === null) {
        throw new Error("ShadowRoot is null");
      }

      const playerContainer = document.createElement("div");
      this.shadowRoot.appendChild(playerContainer);

      const sourcepoint = document.querySelector(
        "ws-sourcepoint",
      ) as HTMLElement | null;

      const getConsent = async (): Promise<string> => {
        if (sourcepoint) {
          await window.customElements.whenDefined("ws-sourcepoint");
          const consentString = await (sourcepoint as any).getTcString(); // Adjust the type as needed
          return consentString;
        }
        return ""; // Fallback if sourcepoint is not present
      };

      const player = await createPlayerUI(
        playerContainer,
        {
          ...this.#loaderConfig,
          consentString: sourcepoint ? await getConsent() : "",
        },
        this.#uiConfig,
      );

      await player.loadMedia(this.#mediaConfig);

      this.dispatchEvent(new CustomEvent(INIT));
    } catch (e) {
      console.error("Error creating player UI and loading media:", e);
    }
  }
}

customElements.get("ws-media") ??
  customElements.define("ws-media", MediaPlayer);

declare global {
  interface HTMLElementTagNameMap {
    "ws-media": MediaPlayer;
  }
  interface Window {
    MoltenBundle: any;
  }
}
